import axios from 'axios';
import {blacklist} from "./badwordslist";
import { differenceInYears, differenceInMonths } from 'date-fns'

const ZIP_API_KEY = 'b7c568b0-06ba-11ec-844e-d1dbb0080119';
const PATTERN_BLACK_LIST = new RegExp(`(^|\\W)(${blacklist.join('|')})($|\\W)`, 'gmi')
//valid front end email regex
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PATHS = [
  '/',
  '/hernia',
  '/roundup',
  '/roundup2',
  '/talcum',
  '/zantac',
  '/workers-comp',
  '/workers-comp-flow',
  '/workers-comp-v2',
  '/dui',
  '/dui/',
  '/ssdi',
  '/storm-damage',
  '/storm-damage-flow',
  '/workers-comp-vw',
  '/workers-comp-vw2',
  '/workers-comp-vw3',
  '/workers-comp-sms',
  '/workers-comp-v3',
  '/workers-comp-v3/',
  '/camp-lejeune',
  '/camp-lejeune-v2',
  '/camp-lejeune-settlement',
  '/camp-lejeune-settlement/',
  '/camp-lejeune-sms',
  '/camp-lejeune-sms/',
  '/camp-lejeune-toxic-water',
  '/camp-lejeune-toxic-water/',
  '/mva',
  '/mva/',
  '/wc-multi',
  '/wc-multi/',
  '/tws-v2',
  '/tws-v2/',
]

export const ACCIDENT_LIMITS_RANGE = [
  { state: "Alabama", years: 2 },
  { state: "Alaska", years: 2 },
  { state: "Arizona", years: 2 },
  { state: "Arkansas", years: 3 },
  { state: "California", years: 2 },
  { state: "Colorado", years: 3 },
  { state: "Connecticut", years: 2 },
  { state: "Delaware", years: 2 },
  { state: "DC", years: 3 },
  { state: "Florida", years: 4 },
  { state: "Georgia", years: 2 },
  { state: "Hawaii", years: 2 },
  { state: "Idaho", years: 2 },
  { state: "Illinois", years: 2 },
  { state: "Indiana", years: 2 },
  { state: "Iowa", years: 2 },
  { state: "Kansas", years: 2 },
  { state: "Kentucky", years: 1 },
  { state: "Louisiana", years: 1 },
  { state: "Maine", years: 6 },
  { state: "Maryland", years: 3 },
  { state: "Massachusetts", years: 3 },
  { state: "Michigan", years: 3 },
  { state: "Minnesota", years: 2 },
  { state: "Mississippi", years: 3 },
  { state: "Missouri", years: 5 },
  { state: "Montana", years: 3 },
  { state: "Nebraska", years: 4 },
  { state: "Nevada", years: 2 },
  { state: "New Hampshire", years: 3 },
  { state: "New Jersey", years: 2 },
  { state: "New Mexico", years: 3 },
  { state: "New York", years: 3 },
  { state: "North Carolina", years: 3 },
  { state: "North Dakota", years: 6 },
  { state: "Ohio", years: 2 },
  { state: "Oklahoma", years: 2 },
  { state: "Oregon", years: 2 },
  { state: "Pennsylvania", years: 2 },
  { state: "Rhode Island", years: 3 },
  { state: "South Carolina", years: 3 },
  { state: "South Dakota", years: 3 },
  { state: "Tennessee", years: 1 },
  { state: "Texas", years: 2 },
  { state: "Utah", years: 4 },
  { state: "Vermont", years: 3 },
  { state: "Virginia", years: 2 },
  { state: "Washington", years: 3 },
  { state: "West Virginia", years: 2 },
  { state: "Wisconsin", years: 3 },
  { state: "Wyoming", years: 4 },
]

export const MONTHS = [
  {
    month: 'January',
    val: '01'
  },
  {
    month: 'February',
    val: '02'
  },
  {
    month: 'March',
    val: '03'
  },
  {
    month: 'April',
    val: '04'
  },
  {
    month: 'May',
    val: '05'
  },
  {
    month: 'June',
    val: '06'
  },
  {
    month: 'July',
    val: '07'
  },
  {
    month: 'August',
    val: '08'
  },
  {
    month: 'September',
    val: '09'
  },
  {
    month: 'October',
    val: '10'
  },
  {
    month: 'November',
    val: '11'
  },
  {
    month: 'December',
    val: '12'
  }
]
//helper functions

//front end validations
export const isFieldValueIsEmpty = (inputValue) => {
  return inputValue.length === 0;
}

export const checkIfAccidentExceedsYear = (yearLimit, injuryDate) => {
  const today = new Date()
  if (differenceInYears(today, injuryDate) >= yearLimit) {
    return 'yes'
  } else {
    return 'no'
  }
}

export const getJornayaLeadIdToken = () => {
  return new Promise(res => {
    const i = setInterval(() => {
      const input = document.getElementById("leadid_token")
      let val = ''
      if (input) {
        val = input.value
      }
      if (val) {
        clearInterval(i)
        res(val)
      }
    }, 5)
  })
}

export const getRetreaverNumber = () => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (window.RetreaverNumber) {
        clearInterval(interval)
        resolve({
          retreaverNumber: window.RetreaverNumber.get("number"),
          retreaverNumberFormatted: window.RetreaverNumber.get("formatted_number")
        })
      }
    }, 5)
  })
}

export const setRetreaverCampaign = () => {
  const a=document.createElement("script");

  const pathName = window.location.pathname
  let campaignKey = ''

  switch (pathName) {
    case '/':
      break
    case '/storm-damage-flow':
    case '/storm-damage':
      campaignKey = '5fe686b3facf90eee0000ab59c5441ab'
      break
    case '/ssdi':
      campaignKey = 'bb28f2ce1be336c8924010c623f68b19'
      break
    case '/camp-lejeune-v2':
    case '/camp-lejeune-v2/':
      campaignKey = '7ebaeab86367bfe545844b6627c94a4a'
      break
    default:
      campaignKey = 'c2bb6db96b197da8a1521279b1812805'
  }

  a.type="text/javascript";
  a.async=!0;
  a.defer=!0;
  a.src=document.location.protocol+"//dist.routingapi.com/jsapi/v1/retreaver.min.js";
  a.onload=a.onreadystatechange=function(){
    Retreaver.configure({
      host:"api.routingapi.com",
      prefix:"https:"==document.location.protocol?"https":"http"
    });

    (window.RetreaverCampaign = new Retreaver.Campaign({
      campaign_key: campaignKey
    }))

    window.RetreaverCampaign.request_number(function (number) {
      window.RetreaverNumber = number
    })
  };
  (document.getElementsByTagName("head")[0]||document.getElementsByTagName("body")[0]).appendChild(a)
}


export const getTrustedFormByPromise = () => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      const trustedForm = getTrustedForm()
      if (trustedForm.trustedFormUrl.length > 0) {
        clearInterval(interval)
        resolve(trustedForm)
      }
    }, 5)
  })
}

export const countDescriptionCharacters = (description) => {
  //first we need to eliminate all the white spaces in between words, we'll do this with a regex function
  description = description.replace(/ +/g, "");
  return description.length
}

export const inTime = async () => {
  //temporary fix until server file is made
  const time = Date.now()
  let date = new Date(time)
  const pstDate = new Date(date.toLocaleString("en-US", {timeZone: "America/Los_Angeles"}))
  const hourPST = pstDate.getHours()
  const day = pstDate.getDay()

  return hourPST >= 9 && hourPST <= 16 && day >= 1 && day <= 5
}

export async function inRegion() {
  try {
    const response = await fetch('https://ipapi.co/json')
    const {country, region_code: regionCode} = await response.json()

    if (country !== 'US') {
      throw {}
    }

    return {success: true, country, regionCode}
  } catch (err) {
    return {success: false}
  }
}

export const extractUrlParams = () => {
  const urlSearchParams = new URLSearchParams(location.search)

  const obj = {}

  urlSearchParams.forEach((value, key) => {
    switch (key) {
      case 's1':
        obj['sub_id1'] = checkForPlaceholder(value) || '1'
        break
      case 'sub2':
        obj['sub_id2'] = checkForPlaceholder(value)
        break
      case 'sub3':
        obj['sub_id3'] = checkForPlaceholder(value)
        break
      case 'sub4':
        obj['sub_id4'] = checkForPlaceholder(value)
        break
      case 'sub5':
        obj['click_id'] = checkForPlaceholder(value) || ''
        break
      default:
        obj[key] = checkForPlaceholder(value)
    }
  })

  if (obj['sub1']) {
    obj['sub_id1'] = obj['sub1'] || '1'
  }

  if (obj['sub_id']) {
    obj['sub_id1'] = obj['sub_id'] || '1'
  }

  if (!obj['sub_id1']) {
    obj['sub_id1'] = '1'
  }

  if (obj['sub5']) {
    obj['click_id'] = obj['sub5']
    delete obj['sub5'];
  }

  return obj;
}

//check for placeholder from url params, i.e {first_name} is a placeholder, John (without curly brackets) would be okay
export const checkForPlaceholder = text => /^{.+}$/g.test((text || '').trim()) ? '' : text

export const getIpAddress = async () => {
  let ip = '';
  try {
    const r = await fetch("https://api.ipify.org?format=json")
    const response = await r.json()
    ip = response.ip
  } catch (e) {
    const r = await fetch('https://www.cloudflare.com/cdn-cgi/trace')
    const response = await r.text()
    ip = response.match(/ip=([^\n]+)/)[1]
  }
  return ip
}

export const setYearList = (yearMin, currentYear, yearArray) => {
  for (let i = yearMin; i <= currentYear; i++) {
    yearArray.unshift({val: i})
  }
}

//TODO: Make other components use this method of returning an array of years **LOW PRIORITY**
export const setYearsRange = (yearMin) => {
  const currentYear = new Date().getFullYear()
  return Array(currentYear - yearMin + 1).fill().map((_, idx) => currentYear - idx)
}

export function extractAllUrlParamsAndSetThemInVuexStore() {
  const paramsFromUrl = extractUrlParams()

  // Don't extract these params from URL if one of them is missing
  if (!('lp_campaign_id' && 'lp_supplier_id' && 'lp_key' in paramsFromUrl)) {
    delete paramsFromUrl['lp_campaign_id']
    delete paramsFromUrl['lp_supplier_id']
    delete paramsFromUrl['lp_key']
  }

  const setAnswersFormUrl = []

  //get any params from the url and set them as fields in vuex store
  for (const [key, value] of Object.entries(paramsFromUrl)) {
    setAnswersFormUrl.push({field: key, value: value})
  }

  return setAnswersFormUrl
}

export const getTrustedForm = () => {
  let trustedFormUrl = '';
  let trustedFormId = '';
  if (document.getElementById('xxTrustedFormCertUrl_0')) {
    const tfCertUrl = document.getElementById('xxTrustedFormCertUrl_0').value
    const tfParts = tfCertUrl.split("https://cert.trustedform.com/");
    trustedFormUrl = tfCertUrl;
    trustedFormId = tfParts[1];
  }
  return {
    trustedFormUrl,
    trustedFormId
  }
}

//google maps api functions

export const parseAddressObject = (address_components) => {
  const ShouldBeComponent = {
    home: ["street_number"],
    addressZipCode: ["postal_code"],
    street: ["street_address", "route"],
    state: [
      "administrative_area_level_1",
    ],
    county: [
      "administrative_area_level_2",
    ],
    city: [
      "locality",
      "sublocality",
      "sublocality_level_1",
      "sublocality_level_2",
      "sublocality_level_3",
      "sublocality_level_4"
    ],
    zip: [
      'postal_code'
    ]
  };

  const address = {
    home: "",
    addressZipCode: "",
    street: "",
    state: "",
    state_2letters: "",
    county: "",
    city: "",
    zip: ""
  }

  if (address_components !== undefined) {
    address_components.forEach(component => {
      for (let shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === "state") {
            address[shouldBe] = component.long_name;
            address.state_2letters = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    })
  }
  return address;
}

export const scrollToFirstError = (errorClass, selector = '.box', offset = 0) => {
  const block = document.querySelector(`.${errorClass}`)
  if (!block) return
  const el = block.closest(selector)
  window.scrollTo({
    top: el.offsetTop - offset,
    behavior: "smooth"
  })
}

//validations that require requests

export const ipqsIsEmailInvalid = async (email) => {
  let ipqsUrl = 'https://validations.org31415.dev/api/validations/ipqs/email'

  return await axios.post(ipqsUrl, {
    email
  })
    .then(response => {
      if (!response.data.valid) {
        return true
      } else if (response.data.disposable) {
        return true
      } else if (response.data.fraud_score > 90) {
        return true
      } else {
        return false
      }
    })
    .catch(e => {
      console.log('error:', e)
    })
}

export const internalZipLookup = async (zipCode) => {
  return await axios
      .get(`https://zip.org31415.dev/us/${zipCode}`)
      .then(response => {
        if (typeof response.data === 'object' && 'state' in response.data) {
          return {
            county: response.data.county,
            city: response.data.city,
            stateCode: response.data.state,
            state_long: response.data.state_long,
            zipError: false
          }
        } else {
          return {
            zipError: true
          }
        }
      })
      .catch(e => {
        console.log('zipLookup error:', e)
      })
}

export async function ipqsCheckIfPhoneIsValid(phone) {
  //ipqs phone validation only accepts formatted numbers with country code in front
  const formattedNumber = phone.replace(/[()\-\s]+/g, "")
  const ipqsUrl = 'https://validations.org31415.dev/api/validations/ipqs/phone'

  return await axios
    .post(ipqsUrl, {
      phone: formattedNumber
    })
    .then(response => {
      return !response.data.valid || !response.data.active || response.data.fraud_score > 85 || response.data.line_type === 'Toll Free';
    })
    .catch(e => {
      console.log('phone error:', e)
    })
}

export async function ipqsCheckIfPhoneIsValid2(phone) {
  //ipqs phone validation only accepts formatted numbers with country code in front
  const formattedNumber = phone.replace(/[()\-\s]+/g, "")
  const ipqsUrl = 'https://validations.org31415.dev/api/validations/ipqs/phone'

  const response = {
    valid: false,
    active: false,
    fullValid: false
  }

  try {
    const responseFromIpqs = await axios.post(ipqsUrl, {phone: formattedNumber})
    response.valid = responseFromIpqs.data.valid
    response.fullValid = responseFromIpqs.data.valid || responseFromIpqs.data.fraud_score > 85 || responseFromIpqs.data.line_type === 'Toll Free';
    response.active = responseFromIpqs.data.active
  } catch (err) {
    console.log(err)
    return response
  }

  const url = new URLSearchParams(location.search)

  if (url.get('force-inactive') === '1') {
    response.active = false
  }

  return response
}

export const externalDescriptionValidation = async (
  description,
  hasBadWords,
  enoughWords
) => {
  //hasBadWords and enoughWords will be state values
  hasBadWords = description.match(PATTERN_BLACK_LIST) ? 'yes' : 'no';
  enoughWords = description.split(" ").length >= 4 ? 'yes' : 'no';

  let isGibberish = ''

  try {
    const response = await axios.post("https://gibberish.org31415.dev/index.php", {text: description})
    isGibberish = response.data.is_gibberish ? 'yes' : 'no'
  } catch (e) {
  }

  return {
    hasBadWords,
    enoughWords,
    isGibberish
  }
};

export const setEverflow = () => {
  return new Promise(resolve => {
    let offId = null
    const urlParams = new URLSearchParams(window.location.search)
    let currentPath = window.location.pathname
    let inTortPath = PATHS.includes(currentPath)

    if (inTortPath) {
      switch (currentPath) {
        case '/talcum': {
          offId = 46
          break
        }
        case '/roundup':
        case '/roundup2':
          offId = 41
          break
        case '/zantac': {
          offId = 39
          break
        }
        case '/ssdi': {
          offId = 60
          break
        }
        case '/dui':
        case '/dui/': {
          offId = 137
          break
        }
        case '/workers-comp':
        case '/workers-comp/':
        case '/workers-comp-v2':
        case '/workers-comp-v2/':
        case '/workers-comp-sms':
        case '/workers-comp-sms/':
        case '/workers-comp-v3':
        case '/workers-comp-v3/':
          offId = 47
          break
        case '/hernia': {
          offId = 50
          break
        }
        case '/storm-damage':
        case '/storm-damage-flow':
          if (urlParams.get('oid')) {
            offId = urlParams.get('oid')
            break
          }
          offId = 79
          break
        case '/mva': {
          offId = 87
          break
        }
        case '/': {
          offId = 39
          break
        }
        case '/workers-comp-vw3':
        case '/workers-comp-vw3/':
        case '/workers-comp-vw2':
        case '/workers-comp-vw2/':
        case '/workers-comp-vw':
        case '/workers-comp-vw/': {
          offId = 82
          break
        }
        case '/camp-lejeune':
        case '/camp-lejeune/':
        case '/camp-lejeune-v2':
        case '/camp-lejeune-v2/':
        case '/camp-lejeune-sms':
        case '/camp-lejeune-sms/':
        case '/camp-lejeune-toxic-water':
        case '/camp-lejeune-toxic-water/':
        case '/camp-lejeune-settlement':
        case '/camp-lejeune-settlement/': {
          offId = 93
          break
        }
        case '/tws-v2/':
        case '/tws-v2': {
          offId = 106
          break
        }
        case '/wc-multi':
        case '/wc-multi/': {
          offId = 109
          break
        }
      }
    } else {
      return
    }

    const interval = setInterval(() => {
      if (window.EF) {
        const urlParams = new URLSearchParams(window.location.search);
        EF.click({
          offer_id: EF.urlParameter('oid') || offId,
          affiliate_id: EF.urlParameter('affid') || 1,
          sub1: EF.urlParameter('sub1'),
          sub2: EF.urlParameter('sub2'),
          sub3: EF.urlParameter('sub3'),
          sub4: EF.urlParameter('sub4'),
          sub5: EF.urlParameter('sub5'),
          uid: EF.urlParameter('uid'),
          source_id: EF.urlParameter('source_id'),
          transaction_id: EF.urlParameter('_ef_transaction_id'),
        }).then(tid => {
          if (tid) {
            urlParams.set('_ef_transaction_id', tid)
            history.replaceState({}, '', location.origin + location.pathname + '?' + urlParams.toString())
            resolve({ ef_transaction_id : tid })
          }
        })
        clearInterval(interval)
      }
    }, 5)
  })
}

function getDateInCustomFormat() {
  const fullDate = new Date()
  let hours = fullDate.getHours()
  let minutes = fullDate.getMinutes()
  let seconds = fullDate.getSeconds()
  let date = fullDate.getDate()
  let month = fullDate.getMonth() + 1
  const year = fullDate.getFullYear()

  date = String(date).padStart(2, '0')
  month = String(month).padStart(2, '0')
  hours = String(hours).padStart(2, '0')
  minutes = String(minutes).padStart(2, '0')
  seconds = String(seconds).padStart(2, '0')

  return (`${month}/${date}/${year} ${hours}:${minutes}:${seconds}`)
}

const appendMissingParams = (params) => {
  const urlParams = new URLSearchParams(location.search)

  for (const param in params) {
    if (!urlParams.has(param)) {
      urlParams.append(param, params[param])
    }
  }

  return urlParams.toString()
}

export function goToThankYouPage(affid, subId, page, sub_id1) {
  const searchParameters = appendMissingParams({
    'affid' : affid,
    'sub_id' : subId,
    'page': page,
    'sub1,': sub_id1,
  })

  const flagForStyle = window.location.pathname === '/workers-comp'
  window.location.href = '/thank-you?' + searchParameters + (flagForStyle ? '&s=aat' : '')
}

export function goToDisqualify(affid, subId, reason) {
  const searchParameters = appendMissingParams({
    'affid' : affid,
    'sub_id' : subId,
    'reason' : reason
  })

  window.location.href = '/disqualify?' + searchParameters
}

export const postData = async (lp_data, tort_data = {}, sessionId) => {
  const {
    campaignId,
    supplierId,
    key,
    firstName,
    lastName,
    email,
    phoneNumber,
    zipCode,
    city,
    state,
    county,
    hasLawyer,
    description,
    injuryType,
    injuryCause,
    hasBadWords,
    enoughWords,
    isGibberish,
    ipAddress,
    address,
    jornayaId,
    oid,
  } = lp_data

  try {
    const urlParams = extractUrlParams();
    let affid = 1;
    let sub_id1 = '';

    if ("affid" in urlParams) {
      affid = urlParams.affid
    }
    if ("sub_id1" in urlParams) {
      sub_id1 = urlParams.sub_id
    }

    // CAMPAIGN Default
    let lp_campaign_id = campaignId
    let lp_supplier_id = supplierId
    let lp_key = key;

    let ef_transaction_id = ''

    if ('_at' in window) {
      if (affid) {
        window._at.track("attribute", {
          "aff_id": affid
        });
      }
    }

    // Below we overwrite these values, in case we got them from URL

    if ("lp_campaign_id" in urlParams && "lp_supplier_id" in urlParams && "lp_key" in urlParams) {
      lp_campaign_id = urlParams.lp_campaign_id
      lp_supplier_id = urlParams.lp_supplier_id
      lp_key = urlParams.lp_key
    }
    if ("_ef_transaction_id" in urlParams) {
      //reassign key to new and delete old key
      ef_transaction_id = urlParams._ef_transaction_id
      delete urlParams._ef_transaction_id
    }

    let userAgent = "userAgent" in navigator ? navigator.userAgent : ''

    if ('_at' in window) {
      window._at.track("attribute", {
        "first_name": firstName,
        "last_name": lastName,
        "city": city,
        "state": state,
        "zip": zipCode,
        "email": email
      })
    }

    //name gibberish
    let nameGibberish = isNameGibberish(firstName) ? 'yes' : 'no'
    if (nameGibberish === 'no') {
      nameGibberish = isNameGibberish(lastName) ? 'yes' : 'no'
    }

    //name bad word
    let nameBadWord = PATTERN_BLACK_LIST.test(firstName) ? 'yes' : 'no'
    if (nameBadWord === 'no') {
      nameBadWord = PATTERN_BLACK_LIST.test(lastName) ? 'yes' : 'no'
    }

    const r = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1][Math.floor(Math.random() * 20)]

    let lp_action = ''
    // //here we will determine if we send lp_action test if we are on the DEV server
    // if (window.location.hostname !== 'mylawsuithelp.com') {
    //   lp_action = 'test'
    // }

    const data = {
      lp_campaign_id,
      lp_supplier_id,
      lp_key,
      lp_action,
      ...urlParams,
      first_name: firstName,
      last_name: lastName,
      email,
      phone: phoneNumber.replace(/[()\-\s]+/g, ""),
      zip_code: zipCode,
      city,
      state,
      county,
      case_description: description,
      bad_words: hasBadWords,
      enough_words: enoughWords,
      is_gibberish: isGibberish,
      name_gibberish: nameGibberish,
      name_bad_word: nameBadWord,
      primary_injury: injuryType,
      injury_cause: injuryCause,
      trusted_form_cert_id: lp_data.trustedFormId,
      trusted_form_cert_url: lp_data.trustedFormUrl,
      ef_transaction_id: ef_transaction_id,
      user_agent: userAgent,
      ip_address: ipAddress,
      channel: ['Google', 'Facebook'][r],
      has_lawyer: hasLawyer,
      signup_date: getDateInCustomFormat(),
      address,
      jornayaId,
      oid,
      affid,
      ...tort_data,
    }

    if ('sms_verified' in lp_data) {
      data['sms_verified'] = lp_data['sms_verified']
    }

    if ('qualified' in lp_data) {
      data['qualified'] = lp_data['qualified']
    }

    switch (window.location.pathname) {
      case '/workers-comp':
      case '/workers-comp/':
        data.referrer = 'workers-comp'
        break
      case '/workers-comp-v3':
      case '/workers-comp-v3/':
        data.referrer = 'workers-comp-v3'
        break
      case '/workers-comp-vw':
      case '/workers-comp-vw/':
        data.referrer = 'vw'
        break
      case '/workers-comp-vw2':
      case '/workers-comp-vw2/':
        data.referrer = 'vw2'
        break
      case '/workers-comp-vw3':
      case '/workers-comp-vw3/':
        data.referrer = 'vw3'
        break
      case '/workers-comp-sms':
      case '/workers-comp-sms/':
        data.referrer = 'workers-comp-sms'
        break
      case '/wc-multi':
      case '/wc-multi/':
        data.referrer = 'wc-multi'
        break
      case '/dui':
      case '/dui/':
        data.tcpa_text = lp_data.tcpa_text
        data.landing_page_url = lp_data.landing_page_url
    }

    const backUpData = {
      affid,
      channel: ['Google', 'Facebook'][r],
      ef_transaction_id: ef_transaction_id,
      ip_address: ipAddress,
      jornayaId,
      lp_campaign_id,
      lp_supplier_id,
      lp_key,
      lp_action,
      name_gibberish: nameGibberish,
      name_bad_word: nameBadWord,
      oid,
      signup_date: getDateInCustomFormat(),
      trusted_form_cert_id: lp_data.trustedFormId,
      trusted_form_cert_url: lp_data.trustedFormUrl,
      user_agent: userAgent,
    }

    if (sessionId) {
      await saveUserProgressionAndBackup({sessionId, backUpData})
    }

    const url = 'https://control.org31415.dev/api/leads/ingest'

    const response = await axios.post(url, data)

    return {response, data}
  } catch (error) {
    console.log(error);
    throw error
  }
}

export const pingOnPageLoadWithData = () => {
  let emailFromUrl = ''
  let affid = 1

  const searchParams = new URLSearchParams(location.search)
  const affidFromUrl = searchParams.get('affid')

  if (affidFromUrl !== null) {
    affid = affidFromUrl
  }

  if (searchParams.has('email') || searchParams.has('email_address') || searchParams.has('mail')) {
    emailFromUrl = searchParams.get('email') || searchParams.get('email_address') || searchParams.get('mail')
  }

  pingOnPageLoad(affid, emailFromUrl).then()
}

export const pingOnPageLoad = async (pub_id, email) => {
  await axios.get(`https://wc.api.mylawsuithelp.com/ping?pub_id=${pub_id}&email=${email}`)
}

//advertisement functions
export const getAdvertisement = async () => {

  let availableAds = ['classAction']
  let advertisementSelected = {};
  let advertisementUrl = `https://www.ikd92trk.com/`

  // const unavailableStates = {
  //   blueSky: ['GA', 'MD', 'NV', 'LA', 'SC'],
  //   healthPlan: ['NJ', 'MN']
  // }

  // if (await inTime()) {
  //   const {success, regionCode} = await inRegion()
  //   if (success) {
  //     if (!unavailableStates.blueSky.includes(regionCode)) {
  //       availableAds.push('blueSky', 'bestAutoInsurance')
  //     }
  //     if (!unavailableStates.healthPlan.includes(regionCode)) {
  //       availableAds.push('healthPlansMarket', 'healthPlansAmerica')
  //     }
  //   }
  // }

  const randomNumberForAds = Math.floor(Math.random() * availableAds.length)
  const advertisementRandom = availableAds[randomNumberForAds];


  const advertisements = [
    // {
    //   advertisementName: 'blueSky',
    //   tokenAd: '27P3D6',
    //   img: require('../assets/img/advertisementImgs/bsky_728x984.jpg')
    // },
    // {
    //   advertisementName: 'bestAutoInsurance',
    //   tokenAd: '27P3D6',
    //   img: require('../assets/img/advertisementImgs/209714761_991855071561086_1353197505920699083_n.jpg')
    // },
    // {
    //   advertisementName: 'healthPlansMarket',
    //   tokenAd: '2CTPL1',
    //   img: require('../assets/img/advertisementImgs/healthAdd.jpg')
    // },
    // {
    //   advertisementName: 'healthPlansAmerica',
    //   tokenAd: '2F8LBL',
    //   img: require('../assets/img/advertisementImgs/healthPlansAmerica.jpg')
    // },
    {
      advertisementName: 'classAction',
      baseToken: '4WB1QC',
      tokenAd: '2PKWQ8',
      img: require('../assets/img/advertisementImgs/classActionClaim.png')
    },
    // {
    //   advertisementName: 'legalFunding',
    //   tokenAd: '2N721M',
    //   img: require('../assets/img/advertisementImgs/LegalFunding-3.jpeg')
    // }
  ]

  advertisements.forEach(ad => {
    if (ad.advertisementName === advertisementRandom) {
      advertisementSelected = ad;
    }
  })

  return {
    name: advertisementSelected.advertisementName,
    url: `${advertisementUrl}${advertisementSelected.baseToken}/${advertisementSelected.tokenAd}`,
    img: advertisementSelected.img
  }
}

export const isNameGibberish = (name) => {
  //checking if name has any prefixes or suffixes is highest priority to ignore the name suffix when running name gibberish tests
  name = removePrefixInNameIfExists(name).trim()
  name = removeSuffixInNameIfExists(name).trim()

  //check if name has a special character that connects name ('-') => if name has hyphen, split the str
  //the reason we choose to only remove hyphens is because if we attempt to remove any special character
  //we are defeating the point of checking for name gibberish, if we checked and removed >?!<| etc, we would end up
  //not flagging an incorrect name

  //check if name has a special character that connects name (' ', '-') => if name has hyphen, and replace with empty space string
  name = name.replace(/[-.,]/m, ' ')

  //remove excess white space
  name = name.replace(/\s+/g, ' ').trim()

  const splitName = name.split(' ')

  //remove any initials
  for (let namePart of splitName) {
    if (namePart.length <= 1) {
      splitName.splice(splitName.indexOf(namePart), 1)
    }
  }

  //iterate through array of split names to test individually only if name has hyphen, otherwise proceed with regular check
  return iterateTests(splitName)
}

const regexNameGibberishTest = (name) => {
  // has less than 2 chars
  if (name.length < 2) {
    return true
  }

  // has no vowels
  if (!/[aeiouyāēīōūȳáéíóúýäöü]/i.test(name)) {
    return true
  }

  // has three consecutive chars
  if (/([A-Za-zÀ-ÖØ-öø-ÿ])\1\1+/.test(name)) {
    return true
  }

  // no vowels in 5 chars
  if (/[^aeiouyāēīōūȳáéíóúýäöü]{5}/mi.test(name)) {
    return true
  }
  return false
}

const removePrefixInNameIfExists = (name) => {
  //remove any prefix in name
  const prefixRegex = /^(hr|fr|md|dr|mr|mrs|miss|ms|sir|sr)(\.|\s+)/i
  if (prefixRegex.test(name)) {
    return name.replace(prefixRegex, "").trim()
  }
  return name
}

const removeSuffixInNameIfExists = (name) => {
  //remove suffix in name
  const suffixRegex = /(?:,|-|\s+)(?:i|ii|iii|iv|jr|sr|dds|phd|md|dvm)\.?$/i
  if (suffixRegex.test(name)) {
    return name.replace(suffixRegex, "").trim()
  }
  return name
}

const iterateTests = (nameArray) => {
  for (const name of nameArray) {
    const regexNameGibberish = regexNameGibberishTest(name)
    if (regexNameGibberish) return true
  }
  return false
}

export const twilioPhonePost = async (phone) => {
  const url = 'https://proxy.leadprosper.tech/two-step-verification/phone'
  let response = {}

  try {
    const respFromReq = await axios.post(url, phone)
    response = respFromReq.data

  } catch (err) {
    console.log(err)
    return { sent: false }
  }


  return response
}

export const twilioCodePost = async (code) => {
  const url = 'https://proxy.leadprosper.tech/two-step-verification/code'
  let response = false

  try {
    const respFromReq = await axios.post(url, code)
    response = respFromReq.data.status === 'approved'
  } catch (err) {
    console.log(err)
  }

  return response
}

export const getChannel = () => {
  const r = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1][Math.floor(Math.random() * 20)]
  return ['Google', 'Facebook'][r]
}

export async function startUserSession({ ef_transaction_id, project_slug, affid, sub1 }) {

  return await axios.post(`${process.env.VUE_APP_USER_PROGRESSION_ENDPOINT}/api/start-session`, {
    ef_transaction_id,
    user_agent: window.navigator.userAgent,
    project_slug,
    affid,
    sub1
  }).then(response => {
    return response.data.session_id
  })
}

export const saveUserProgressionAndBackup = async({session, data}) => {
  return axios.post(`${process.env.VUE_APP_USER_PROGRESSION_ENDPOINT}/api/save-data`, {
    session,
    data,
  }).then(response => {
    const backUpStatus = response.data && response.data.userBackupStatus ? response.data.userBackupStatus : false
    const progressStatus = response.data && response.data.userProgressionStatus ? response.data.userProgressionStatus : false
    return {
      userBackupStatus: backUpStatus,
      userProgressionStatus: progressStatus || false
    }
  }).catch(e => {
    return {
      userBackupStatus: false,
      userProgressionStatus: false
    }
  })
}

export function timeframe(injuryDate) {
  const monthsDifference = differenceInMonths(new Date(), injuryDate)
  let incidentTimeframe = ''

  if (monthsDifference < 3) {
    incidentTimeframe = 'Recently'
  } else if (monthsDifference >= 3 && monthsDifference < 6) {
    incidentTimeframe = '3-6 months ago'
  } else if (monthsDifference >= 6 && monthsDifference < 12) {
    incidentTimeframe = '6-12 months ago'
  } else if (monthsDifference >= 12 && monthsDifference < 24) {
    incidentTimeframe = 'More than 1 year ago'
  } else if (monthsDifference >= 24) {
    incidentTimeframe = 'More than 2 years ago'
  }

  return incidentTimeframe
}
